import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'


import { StoreModule } from '@ngrx/store'

import { reducers, effects } from '@app/shop/store'

import { EffectsModule } from '@ngrx/effects'
import { SharedModule, CoreComponentsModule } from '@app/shared'
import { PipesModule } from '@app/shared/pipes'

import { FormsModule } from '@angular/forms'
import { NgxDatatableModule } from '@swimlane/ngx-datatable'

import { ShopServiceExistsGuards } from './guards/shopService-exist.guard'

import { ShopLoadboardComponent } from './components/shop-order-list/shop-order-list.component'
import { ShopModalComponent } from './components/shop-modal/shop-modal.component'
import { ShopFilterComponent } from './components/shop-filters/shop-filter.component'
import { ShopCompletedShopServicesComponent } from './components/completed-shop-service/completed-shop-service.component'
import { ShopServiceModalComponent } from './components/shop-service-modal/shop-service-modal.component'
import { ShopOrderDetailsComponent } from './components/shop-order-details/shop-order-details.component'
import { ShopOrderItemRouterComponent } from './containers/shop-order-item-router.component'
import { ShopOrderHistoryComponent } from './components/shop-order-history/shop-order-history.component'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { MaskitoDirective } from '@maskito/angular'
import { NgxPopperjsModule } from 'ngx-popperjs'
import { SortablejsModule } from 'nxt-sortablejs'

const ShopRouting = RouterModule.forChild([
  {
    path: '',
    component: ShopLoadboardComponent
  },
  {
    path: ':orderId',
    component: ShopOrderItemRouterComponent,
    data: { permissionKey: 'shop_order_read' },
    children: [
      {
        path: '',
        component: ShopOrderDetailsComponent,
        canActivate: [ ShopServiceExistsGuards ],
      },
      {
        path: 'history',
        component: ShopOrderHistoryComponent,
        canActivate: [ ShopServiceExistsGuards ],
      },
    ]
  }
])


@NgModule({
  declarations: [
    ShopLoadboardComponent,
    ShopOrderItemRouterComponent,
    ShopModalComponent,
    ShopFilterComponent,
    ShopOrderDetailsComponent,
    ShopCompletedShopServicesComponent,
    ShopServiceModalComponent,
    ShopOrderHistoryComponent
  ],
  imports: [
    ShopRouting,
    CommonModule,
    SharedModule,
    CoreComponentsModule,
    PipesModule,
    NgbModule,
    FormsModule,
    MaskitoDirective,
    NgxPopperjsModule,
    StoreModule.forFeature('shop', reducers),
    EffectsModule.forFeature(effects),
    NgxDatatableModule,
    SortablejsModule
  ],
  exports: [
    MaskitoDirective
  ],
  providers: [ShopServiceExistsGuards]
})
export class ShopModule {
  constructor() {
    console.log('[Module] : Loaded ShopModule')
  }
}
