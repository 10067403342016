<div>
  <div class="modal-header">
    <h3>Create New Service</h3>
    <button class="btn btn-link" type="button" (click)="close()" aria-label="Close">
      <span aria-hidden="true">
        <i class="fas fa-times"></i>
      </span>
    </button>
  </div>
  <div class="modal-body">
      <div class="row">
        <div class="col-6">
          <div class="floating-input-form">
            <select class="floating-input-form__input" id="status-select" (change)="setShopService('status', $event)">
              <option value="NEW">NEW</option>
              <option value="ACTIVE">ACTIVE</option>
              <option value="COMPLETED">COMPLETED</option>
              <option value="CLOSED">CLOSED</option>
            </select>
            <p class="floating-input-form__label">Status</p>
            <fieldset class="jss1"><legend class="jss3"><span>Status</span></legend></fieldset>
          </div>
        </div>
        <div class="col-6">
          <div class="floating-input-form">
            <select
              class="floating-input-form__input" 
              id="repait-type-select" 
              (change)="setShopService('serviceType', $event)">
              <option value="">Select Service Type*</option>
              <option [ngValue]="'ABS'">Air / ABS / Breaks</option>
              <option [ngValue]="'Engine'">Engine</option>
              <option [ngValue]="'Hydraulics'">Hydraulics</option>
              <option [ngValue]="'Electrical'">Lights / Electrical</option>
              <option [ngValue]="'Maintenance'">Maintenance</option>
              <option [ngValue]="'PM Services'">PM Services</option>
              <option [ngValue]="'Tires'">Tires</option>
              <option [ngValue]="'Transmission'">Transmission</option>
            </select>
            <p class="floating-input-form__label">Service Type*</p>
            <fieldset 
              class="jss1"
              [ngClass]="{'error-outline': onClick && !service.serviceType && form.get('serviceType').invalid}"
            ><legend class="jss3"><span>Service Type*</span></legend></fieldset>
          </div>
          <div class="error" *ngIf="onClick && !service.serviceType && form.get('serviceType').invalid">
            <p class="invalid">*Service Type is invalid</p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <app-search
            *ngIf="!carrierId"
            [label]="'Carrier'"
            [resource]="'CONTACT'"
            [idInfo]="'search-carrier'"
            [method]="'search'"
            [options]="{clearAfterSelect: true}"
            [filter]="{type: 'CARRIER', hasOrders: true, limit: 25, sort: 'quoteCount', dir: 'desc'}"
            (onChange)="setCarrier($event._id, $event.name)"
          ></app-search>
          <div
            *ngIf="carrierId"
            class="floating-input-form"
          >
            <app-btn-confirm
                class="removeBtn"
                (onConfirm)="setCarrier(null, null)"
            ></app-btn-confirm>
            <input 
              value="{{ carrierName }}" 
              disabled="disabled" 
              class="floating-input-form__input"
              [ngClass]="{'error-outline': onClick && !carrierId && form.get('carrierId').invalid}"
            />
            <label class="floating-input-form__label">Carrier*</label>
            <fieldset 
              class="jss1"><legend class="jss3"><span>Carrier*</span></legend></fieldset>
          </div>
          <div class="error" *ngIf="onClick && !carrierId && form.get('carrierId').invalid">
            <p class="invalid" style="margin-top: -12px;">*Carrier is invalid</p>
          </div>
        </div>
        <div class="col-6">
          <app-input 
            [label]="'Odometer'" 
            [value]="service?.odometer" 
            [type]="'number'" 
            [isReadOnly]="service?.unit?.unitType == 'TRAILER'"
            (onSave)="setOdometer($event)"> </app-input>
        </div>
      </div>
      <div  class="row">
        <div class="col-6">
          <app-search
            *ngIf="!service?.shop?._id"
            [label]="'Truck Shop'"
            [resource]="'CONTACT'"
            [idInfo]="'search-shop'"
            [method]="'search'"
            [options]="{clearAfterSelect: true}"
            [filter]="{type: 'TRUCK SHOP', limit: 25, sort: 'quoteCount', dir: 'desc'}"
            (onChange)="setShop($event)"
          ></app-search>
          <div
            *ngIf="service?.shop?._id"
            class="floating-input-form"
          >
            <app-btn-confirm
                class="removeBtn"
                (onConfirm)="setShop({_id:null, name:null})"
            ></app-btn-confirm>
            <input 
              [value]="service?.shop?.name" 
              disabled="disabled" 
              class="floating-input-form__input"
            />
            <label class="floating-input-form__label">Truck Shop</label>
            <fieldset 
              class="jss1"><legend class="jss3"><span>Truck Shop</span></legend></fieldset>
          </div>
          <div class="error" *ngIf="onClick && (!service.shop || !service.shop._id)">
            <p class="invalid" style="margin-top: -12px;">*Please select Truck Shop</p>
          </div>
        </div>
        <div class="col-6">
          <app-date [label]="'Due By'" [startKey]="'dueBy'" (onSave)="setDueDate($event)"></app-date>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <!-- <app-equipment-search
            *ngIf="!service?.unit?._id"
            class="searchers-contact-search"
            [carrierId]="carrierId"
            [options]="{
              notClearInput: true,
              disableValidation: true,
              showAllOnFocus: true,
              nullable: true
            }"
            [invalidData]="onClick && !service?.unit?._id && form.get('unitId').invalid"
            (onEquipmentChangeWithType)="setEquipment($event._id, $event.name, $event.unitType)"
          ></app-equipment-search> -->
          <div
            *ngIf="service?.unit?._id"
            class="floating-input-form"
          >
            <app-btn-confirm
                class="removeBtn"
                (onConfirm)="setEquipment(null, null, null)"
            ></app-btn-confirm>
            <input 
              value="{{ service?.unit?.name }}" 
              disabled="disabled" 
              class="floating-input-form__input"/>
            <label class="floating-input-form">Unit*</label>
          </div>
          <div class="error" *ngIf="onClick && !service?.unit?._id && form.get('unitId').invalid">
            <p class="invalid" style="margin-top: -12px;">*Unit is invalid</p>
          </div>
        </div>
        <div class="col-6">
          <app-search
            *ngIf="!service?.mechanic?._id"
            [label]="'Mechanic'"
            [resource]="'CONTACT'"
            [idInfo]="'search-mechanic'"
            [method]="'search'"
            [options]="{clearAfterSelect: true}"
            [filter]="{accountId: carrierId, type: 'MECHANIC', limit: 25, sort: 'quoteCount', dir: 'desc'}"
            (onChange)="setMechanic($event)"
          ></app-search>
          <div
            *ngIf="service?.mechanic?._id"
            class="floating-input-form"
          >
            <app-btn-confirm
                class="removeBtn"
                (onConfirm)="setMechanic({ _id: null, name: null })"
            ></app-btn-confirm>
            <input value="{{ service.mechanic.name }}" disabled="disabled" class="floating-input-form__input"/>
            <label class="floating-input-form__label">Mechanic*</label>
            <fieldset class="jss1"><legend class="jss3"><span>Mechanic*</span></legend></fieldset>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="floating-input-form">
            <textarea
              class="floating-input-form__input"
              [(ngModel)]="service.desc"
              style="width: 100%"
              placeholder="Enter Description here..."
              type="text"
            ></textarea>
            <p class="floating-input-form__label">Description</p>
            <fieldset class="jss1"><legend class="jss3"><span>Description</span></legend></fieldset>
          </div>
        </div>
      </div>
  </div>
  <div class="modal-footer">
    <button
      class="btn btn-primary"
      (click)="save()"
    >
      Save
    </button>
  </div>
</div>
