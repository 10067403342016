<div *ngVar="{ locked: locked$ | async } as observables">
  <div #shopOrder class="pb-3 py-3 reletive" (click)="selectedService !== null ? this.selectedService = null : null">
    <div class="">
      <div class="head-label-wr relative">
        @if(currentShopOrder){
          <button class="back-btn" routerLink="/shop">
            <i class="fa-solid fa-arrow-left"></i>
          </button>
          <h3 class="head-label">Shop #{{ currentShopOrder?.orderNumber }}</h3>
          <div class="created">
            <span>Last Modified: {{ currentShopOrder?.updated?.at | date: 'short' }}</span>
            <app-account-info-popover [position]="'BOTTOM'" [creator]="currentShopOrder?.updated?.by"></app-account-info-popover>
          </div>
          <button class="shop-order-action" [popper]="orderAction" [popperDisableStyle]="true" [popperPlacement]="NgxPopperjsPlacements.LEFT" [popperTrigger]="NgxPopperjsTriggers.click" [popperHideOnClickOutside]="true">
            <i class="fa-solid fa-bolt"></i>
          </button>
          <popper-content #orderAction class="z-50">
            <div class="shadow-lg orderAction-popover border border-gray-100 rounded-lg bg-white">
              <button (click)="orderAction.hide()" [routerLink]="'history'" title="Show History">
                <i id="history-link-i" class="fas fa-history"></i>
                <span>Show History</span>
              </button>
              <button type="button" title="Clone Work Order" class="" (click)="clone(); orderAction.hide()">
                <i class="far fa-copy"></i>
                <span>Clone ShopOrder</span>
              </button>
              <button *ngIf="!currentShopOrder?._gen_pdf" type="button" title="Generate PDF" class="" (click)="generatePdf(); orderAction.hide()">
                <i class="fa-solid fa-file-pdf"></i>
                <span>Generate PDF</span>
              </button>
              <button *ngIf="!currentShopOrder?._gen_pdf" type="button" title="Get Blank" class="" (click)="generateBlank(); orderAction.hide()">
                <i class="fa-regular fa-file-pdf"></i>
                <span>Get Blank PDF</span>
              </button>
              <button *ngIf="currentShopOrder?._gen_pdf" (click)="orderAction.hide()" class="btn btn-sm btn-outline-secondary mr-3">
                <i class="fas fa-spinner fa-pulse"></i>
                <span>Loading...</span>
              </button>
              <button *ngIf="!observables.locked" type="button" title="Delete ShopOrder" class="border-t border-gray-100" (click)="deleteShopOrder(); orderAction.hide()">
                <i class="fas fa-trash" style="color: #F05252;"></i>
                <span style="color: #F05252;">Delete Shop Order</span>
              </button>
            </div>
          </popper-content>
        }@else {
          <div class="w-1/2">
            <app-preloader [type]="'LARGE_LIST'" [count]="1" [customHeight]="'40px'"></app-preloader>
          </div>
        }
      </div>
    </div>
    <div class="flex shop_contact">
      <div class="shop-cont-left">
        <div class="shop-contact-details h-full">
          <div class="shop-contact-wr">
            <div class="rounded-lg shop-det relative">
              <p class="title-det absolute">Shop</p>
              @if(currentShopOrder?._id){
                <div class="shop-item-shop relative" [ngClass]="{'shop-item-no-content': !currentShopOrder?.shop?._id}">
                  @if (!observables.locked) {
                    <i *ngIf="currentShopOrder?.shop?._id" class="fa-solid fa-shop shop-item-icon"></i>
                  }
                  @if (!observables.locked) {
                    <div class="delete-acc">
                      <app-btn-confirm
                        *ngIf="currentShopOrder?.shop?._id"
                        (onConfirm)="removeShop(currentShopOrder?._shop)"
                        [id]='"-currentShopOrder"'
                      ></app-btn-confirm>
                    </div>
                  }
                  <div class="info-det" *ngIf="currentShopOrder?.shop?._id">
                    <div class="info-det-left">
                      <div class="info-det-item">
                        <p class="titles"><i class="fa-solid fa-shop shop-item-icon-small"></i>Shop</p>
                        <p class="velues">{{currentShopOrder?._shop?.name}}</p>
                      </div>
                      <div class="info-det-item">
                        <p class="titles">Address</p>
                        <p class="velues">{{currentShopOrder?._shop?.address?.addressLine1}}</p>
                      </div>
                    </div>
                    <div class="info-det-right">
                      <div class="info-det-item">
                        <p class="titles">Type</p>
                        <p class="velues">{{currentShopOrder?._shop?.type}}</p>
                      </div>
                      <div *ngIf="currentShopOrder?._shop?.phones?.length" class="info-det-item">
                        <p class="titles">Phones</p>
                        @for (phone of currentShopOrder?._shop?.phones; track $index) {
                          <input class="values-input border-none p-0" [disabled]="true" [ngModel]="maskTransform(phone?.value, {mask: inputMasks[type]})" />
                        }
                      </div>
                    </div>
                  </div>
                  <div class="w-full" *ngIf="!currentShopOrder?.shop?._id">
                    <div class="title-edits">Truck Shop</div>
                    <app-search
                      [idInfo]="'truck-shop'"
                      [label]="'Select Truck Shop'"
                      [resource]="'CONTACT'"
                      [options]="{hideCreate: true}"
                      [isReadOnly]="observables.locked"
                      [method]="'search'"
                      [filter]="{type: 'TRUCK SHOP', limit: 25, sort: 'quoteCount', dir: 'desc', active: true, isAccount: true}"
                      (onChange)="setShop($event._id, $event.name)"
                    ></app-search>
                  </div>
                </div>
              }@else {
                <div class="p-2">
                  <app-preloader [type]="'LARGE_LIST'" [count]="2" [customHeight]="'40px'"></app-preloader>
                </div>
              }
            </div>
            <div class="customer-det rounded-lg relative">
              <p class="title-det absolute">Customer</p>
              @if(currentShopOrder?._id){
                <div class="shop-item flex relative" [ngClass]="{'shop-item-no-content': !currentShopOrder?.customerId}">
                  <div class="delete-acc">
                    @if (!observables.locked && currentShopOrder?.customerId && !services()?.length) {
                      <app-btn-confirm
                        (onConfirm)="removeCustomer(currentShopOrder?.customerId)"
                        [id]='"-currentShopOrder"'
                      ></app-btn-confirm>
                    }
                  </div>
                  <i class="fa-solid fa-user shop-item-icon" *ngIf="currentShopOrder?.customerId"></i>
                  <div class="info-det" *ngIf="currentShopOrder?.customerId">
                    <div class="info-det-left">
                      <div class="info-det-item">
                        <p class="titles"><i class="fa-solid fa-user shop-item-icon-small"></i>Customer</p>
                        <p class="velues">{{currentShopOrder?._customer?.name}}</p>
                      </div>
                      <div class="info-det-item">
                        <p class="titles">Email</p>
                        @for(email of currentShopOrder?._customer?.emails; track $index) {
                          <p class="velues velues-email">{{email?.value}}</p>
                        }
                      </div>
                      <div class="info-det-item">
                        <p class="titles">Address</p>
                        <p class="velues">{{currentShopOrder?._customer?.address?.addressLine1}}</p>
                      </div>
                    </div>
                    <div class="info-det-right">
                      <div class="info-det-item">
                        <p class="titles">Type</p>
                        <p class="velues">{{currentShopOrder?._customer?.type}}</p>
                      </div>
                      <div class="info-det-item flex flex-col" *ngIf="currentShopOrder?._customer?.phones?.length">
                        <p class="titles">Phones</p>
                        @for (phone of currentShopOrder?._customer?.phones; track $index) {
                          <input class="values-input border-none p-0" [disabled]="true" [ngModel]="maskTransform(phone?.value, {mask: inputMasks[type]})" />
                        }
                      </div>
                    </div>
                  </div>
                  <div class="w-full" *ngIf="!currentShopOrder?.customerId">
                    <div class="title-edits">Truck Shop</div>
                    <app-search
                      [idInfo]="'customerId'"
                      [label]="'Select'"
                      [resource]="'CONTACT'"
                      [options]="{hideCreate: true}"
                      [isReadOnly]="observables.locked"
                      [method]="'search'"
                      [filter]="{limit: 25, sort: 'quoteCount', dir: 'desc', active: true, isAccount: true}"
                      (onChange)="setCustomer($event._id)"
                    ></app-search>
                  </div>
                </div>
              }@else {
                <div class="p-2">
                  <app-preloader [type]="'LARGE_LIST'" [count]="3" [customHeight]="'40px'"></app-preloader>
                </div>
              }
            </div>
            <div class="rounded-lg transport-type-det relative">
              <p class="title-det absolute">Truck - Trailer</p>
              @if(currentShopOrder?._id){
                <div class="shop-item-padd relative" [ngClass]="{'block truck-trailer-ampty-wr': !currentShopOrder?.customerId || !currentShopOrder?._units?.length, 'pr-3': currentShopOrder?._units?.length === 1}">
                  <div *ngIf="!currentShopOrder?.customerId" class="truck-trailer-ampty">
                    <p style="white-space: nowrap;">Please Select a</p>
                    <label for="customerId-search-input" class="add-customers">Customer</label>
                  </div>
                  <button *ngIf="services()?.length && currentShopOrder?._units?.length && !observables.locked" [disabled]="observables.locked" (click)="editUnit = !editUnit" class="change-units text-gray-900 font-medium">{{!editUnit?'Change':'Back'}}</button>
                  <div *ngIf="(!currentShopOrder?._units?.length || editUnit) && currentShopOrder?.customerId" class="editUnits w-full">
                    <p class="title-edits">Change Unit</p>
                    <app-search
                      *ngIf="!currentShopOrder?._units?.length"
                      [idInfo]="'equipment-search'"
                      [label]="'Unit'"
                      [resource]="'EQUIPMENT'"
                      [options]="{focus: true, clearAfterSelect: true}"
                      [method]="'search'"
                      [filter]="{carrierId: currentShopOrder.customerId}"
                      (onChange)="setUnit($event)"
                    ></app-search>
                    <app-search
                      *ngIf="editUnit"
                      [idInfo]="'equipment-search'"
                      [label]="'Unit'"
                      [resource]="'EQUIPMENT'"
                      [options]="{focus: true, clearAfterSelect: true}"
                      [method]="'search'"
                      [filter]="{carrierId: currentShopOrder.customerId}"
                      (onChange)="replaceUnit($event)"
                    ></app-search>
                  </div>
                  <div *ngIf="!editUnit && currentShopOrder?.customerId && currentShopOrder?._units?.length" class="item_units-wr">
                    @for (unit of currentShopOrder?._units | sort : 'equipmentType': { reverse: true }; track $index) {
                      <div class="shop-item_units">
                        <i class="fa-solid shop-item-icon" [ngClass]="{'fa-truck': unit?.equipmentType == 'TRUCK', 'fa-trailer': unit?.equipmentType == 'TRAILER'}"></i>
                        <div class="info-det relative">
                          <div class="">
                            <div class="info-det-item">
                              <p *ngIf="unit?.equipmentType == 'TRUCK'" class="titles"><i class="fa-solid fa-truck shop-item-icon-small"></i>Truck</p>
                              <p *ngIf="unit?.equipmentType == 'TRAILER'" class="titles"><i class="fa-solid fa-trailer shop-item-icon-small"></i>Trailer</p>
                              <p class="velues">{{unit?.year}} {{unit?.make}} {{unit?.model}}</p>
                            </div>
                            <div class="info-det-item" *ngIf="unit.registrationNumber">
                              <p class="titles">Plate</p>
                              <p class="velues">{{unit?.registrationNumber}}</p>
                            </div>
                            <div class="info-det-item" *ngIf="unit?.VIN">
                              <p class="titles">Unit</p>
                              <p class="velues">{{unit?.details}} {{unit?.equipmentType}}</p>
                            </div>
                            <div class="info-det-item" *ngIf="unit?.VIN">
                              <p class="titles">VIN</p>
                              <p class="velues">{{unit?.VIN}}</p>
                            </div>
                            <div class="info-det-item" *ngIf="unit.registrationNumber">
                              <p class="titles">Plate</p>
                              <p class="velues">{{unit?.registrationNumber}}</p>
                            </div>
                          </div>
                          <app-btn-confirm *ngIf="!services()?.length && !observables.locked" class="href position-absolute" style="right:10px; top:-4px"
                          [isReadOnly]="observables.locked"(onConfirm)="removeUnit(unit?._id)"></app-btn-confirm>
                        </div>
                      </div>
                    }
                    <div class="flex items-center" style="flex: 1" *ngIf="!editUnit && currentShopOrder.unit.length===1&&currentShopOrder?._linkedUnit[0]?._id">
                      <button class="add-unit-btns" (click)="setUnit({
                          _id:currentShopOrder._linkedUnit[0]._id,
                          name:currentShopOrder._linkedUnit[0]?.details + '-' + currentShopOrder._linkedUnit[0]?.make + currentShopOrder._linkedUnit[0]?.model + currentShopOrder._linkedUnit[0]?.year,
                          equipmentType: currentShopOrder._linkedUnit[0].equipmentType
                        })">
                        Add {{currentShopOrder._linkedUnit[0].equipmentType}} (Unit {{currentShopOrder._linkedUnit[0]?.details}})
                      </button>
                    </div>
                  </div>
                </div>
              }@else {
                <div class="p-2">
                  <app-preloader [type]="'LARGE_LIST'" [count]="4" [customHeight]="'40px'"></app-preloader>
                </div>
              }
            </div>
          </div>
          <div class="searct_units_wr">
            <div class="searct-service-det rounded-lg">
              <div class="relative search-all-services">
                <app-input
                  (onInput)="searchService($event)"
                  [value]="filterValue()"
                  [icon]="'fa-solid fa-magnifying-glass text-gray-500'"
                  [options]="{
                    borderNone: true
                  }"
                  [placeholder]="'Search by description'"
                  id="shop-item-search"
                >
                </app-input>
              </div>
            </div>
            <div *ngIf="!editUnit" class="odometer-det shop-item rounded-lg flex items-center justify-between">
              @if(currentShopOrder?._id) {
                <app-input [title]="'Odometer'" [isReadOnly]="observables.locked" [value]="currentShopOrder?.odometer ? currentShopOrder?.odometer?.toString() : '0'" [label]="'Odometer'"
                [type]="'float-number'" [options]="{borderNone: true}" (onSave)="setOdometer($event)"></app-input>

                <div class="flex items-center justify-between">
                  <span class="Last-WO" *ngIf="currentShopOrder?._prevOdometer">Last WO {{currentShopOrder?._prevOdometer | number}}</span>
                  <button class="realOdometer-location" *ngIf="currentShopOrder?._realOdometer?.value" [popper]="_prevOdometer" [popperDisableStyle]="true" [popperPlacement]="NgxPopperjsPlacements.TOP" [popperTrigger]="NgxPopperjsTriggers.click" [popperHideOnClickOutside]="true">
                    <i class="fa-solid fa-location-crosshairs"></i>
                  </button>
                  <popper-content #_prevOdometer class="z-50">
                    <div  class="current-odometer-popover shadow-lg">
                      <div class="title">
                        <i class="fa-solid fa-location-crosshairs"></i>
                        <span>Current Odometer</span>
                      </div>
                      <div *ngIf="currentShopOrder?._realOdometer?.value" class="pop-content">
                        <span>ODO: <b>{{currentShopOrder?._realOdometer?.value | number}} mi</b></span>
                        <span>({{currentShopOrder?._realOdometer?.time | date: 'short'}})</span>
                        <button *ngIf="!currentShopOrder?.odometer" class="set_realOdometer" [disabled]="observables.locked" (click)="setOdometer(currentShopOrder?._realOdometer?.value)">Set</button>
                      </div>
                    </div>
                  </popper-content>
                </div>
              }@else {
                <div class="w-100">
                  <app-preloader [type]="'LARGE_LIST'" [count]="1" [customHeight]="'40px'"></app-preloader>
                </div>
              }
            </div>
          </div>
          <div [hidden]="!selectedServices?.length" class="selected-det w-full rounded-lg block">
            <div class="selected w-full">
              <div class="inner w-full">
                <div class="selected-list">
                  @for(service of selectedServices; track $index){
                    <span class="selected-order bg-red-100 font-medium"
                    [ngClass]="{
                      'text-red-900 bg-red-100': service?.status?.toLocaleLowerCase() === 'new',
                      'text-yellow-900 bg-yellow-100': service?.status?.toLocaleLowerCase() === 'active',
                      'text-green-900 bg-green-100': service?.status?.toLocaleLowerCase() === 'completed',
                      'text-gray-900 bg-gray-100': service?.status?.toLocaleLowerCase() === 'closed'
                    }"
                    >{{!service.desc ? 'Without Desc' : service?.desc?.length > 50 ? service?.desc.slice(0, 50) + '...' : service?.desc}}
                      <i class="fas fa-times ml-2" (click)="selectService(service)"></i>
                    </span>
                  }
                </div>
                <div class="order-actions">
                  <app-select
                    [label]="'Status'"
                    (onSave)="setServicesStatus($event)"
                    [value]="''"
                    [idInfo]="'setServicesStatus'"
                    [list]="statuses"
                    [keyName]="{key:'_id', name:'name'}"
                    [placeholder]="'Select Status'"
                    [options]="{noFirstOption: true}"
                  ></app-select>

                  <button
                    class="border order-actions-item move-new-order border-gray-200 text-gray-900"
                    (click)="moveServices()"
                  >
                    Move to New Order
                  </button>

                  <button
                    class="delete order-actions-item"
                    title="Delete"
                    (click)="deleteAllSelectedService()"
                  >Delete</button>

                  <button
                    class="unselect order-actions-item"
                    title="Unselect all selected orders"
                    (click)="unselectAllSelectedService()"
                  >Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="shop-cont-right rounded-lg shop-item flex-col">
        @if(currentShopOrder?._id){
          <button [disabled]="observables.locked" class="selectedStatus-btn" #selectedStatusBtn [popper]="selectedStatus" [popperDisableStyle]="true" [popperPlacement]="NgxPopperjsPlacements.BOTTOM" [popperTrigger]="NgxPopperjsTriggers.click" [popperHideOnClickOutside]="true">
            <span
              [ngClass]="{
                'text-red-900 bg-red-100': currentShopOrder?.status === 'NEW',
                'text-yellow-900 bg-yellow-100': currentShopOrder?.status === 'ACTIVE',
                'text-green-900 bg-green-100': currentShopOrder?.status === 'COMPLETED',
                'text-gray-900 bg-gray-100': currentShopOrder?.status === 'CLOSED',
                'text-amber-900 bg-amber-100': currentShopOrder?.status === 'ON_HOLD'
              }"
            >{{currentShopOrder?.status === 'ON_HOLD' ? 'ON-HOLD' : currentShopOrder?.status}}</span>
            <i class="fa-solid" [ngClass]="[selectedStatusContent.offsetWidth !== 0 ? 'fa-caret-up' : 'fa-caret-down']"></i>
          </button>
          <popper-content #selectedStatus class="z-50">
            <div #selectedStatusContent class="shadow-lg selectedStatus-popover border border-gray-100 rounded-lg bg-white flex flex-col" [ngStyle]="{'min-width': selectedStatusBtn.offsetWidth+'px'}">
              <button (click)="setStatus('NEW')">NEW</button>
              <button (click)="setStatus('ON_HOLD')">ON-HOLD</button>
              <button (click)="setStatus('ACTIVE')">ACTIVE</button>
              <button (click)="setStatus('COMPLETED')">COMPLETED</button>
              <button (click)="setStatus('CLOSED')">CLOSED</button>
            </div>
          </popper-content>

          <div class="refNumber-wr shop-cont-right-item">
            <span class="block title font-medium text-gray-900 dark:text-white">REF Number</span>
            <app-input
              [isReadOnly]="observables.locked"
              (onSave)="updateShopOrder({_id:currentShopOrder?._id, refNumber: $event})"
              [value]="currentShopOrder?.refNumber"
              [options]="{borderNone: true}"
              [placeholder]="'type here...'"
            ></app-input>
          </div>
          <div *ngIf="!currentShopOrder?.driverId && currentShopOrder?.customerId && !observables.locked" class="shop-cont-right-item">
            <app-search
              [idInfo]="'driver'"
              [label]="'Driver'"
              [resource]="'CONTACT'"
              [options]="{hideCreate: true, borderNone: true}"
              [isReadOnly]="observables.locked"
              [method]="'search'"
              [filter]="{accountId: currentShopOrder?.customerId, type: 'DRIVER', limit: 25, sort: 'quoteCount', dir: 'desc', active: true, isAccount: false}"
              (onChange)="setDriver($event._id)"
            ></app-search>
          </div>

          <div *ngIf="currentShopOrder?.driverId" class="shop-cont-right-item">
            <span class="block title font-medium text-gray-900 dark:text-white">Driver</span>
            <app-input
              [isReadOnly]="true"
              [disabled]="observables.locked"
              [options]="{canRemove: !observables.locked, borderNone: true}"
              (onRemove)="!observables.locked ? setDriver(null) : null"
              [value]="currentShopOrder?._driver?.name"
            ></app-input>
          </div>

          <div *ngIf="currentShopOrder?.driverId" class="shop-cont-right-item">
            <span class="block title font-medium text-gray-900 dark:text-white">Created By</span>
            <app-input
              [isReadOnly]="true"
              (onRemove)="setDriver(null)"
              [options]="{borderNone: true}"
              [value]="currentShopOrder?.creator?.name"
            ></app-input>
          </div>

          <div class="shop-cont-right-item">
            <span class="block title font-medium text-gray-900 dark:text-white">Created</span>
            <app-date [title]="'Created'" [options]="{borderNone: true, disabled:true}" class="due-by-date" [startKey]="'createdAt'"
            [parent]="currentShopOrder"></app-date>
          </div>
          <div class="shop-cont-right-item">
            <div class="dates-wr">
              <div class="dates-item">
                <span class="block title font-medium text-gray-900 dark:text-white">Arrival:</span>
                <app-date [options]="{disabled:observables.locked, borderNone: true}" class="due-by-date" [startKey]="'arrivalDate'" [parent]="currentShopOrder"
                (onSave)="updateShopOrder($event)"></app-date>
              </div>
              <div class="dates-item">
                <span class="block title font-medium text-gray-900 dark:text-white">Depart:</span>
                <app-date [options]="{disabled:observables.locked, borderNone: true}" [startKey]="'departureDate'" [parent]="currentShopOrder"
                (onSave)="updateShopOrder($event)"></app-date>
              </div>
            </div>
          </div>

          <div class="shop-cont-right-item">
            <div class="dates-wr">
              <div class="dates-item">
                <span class="block title font-medium text-gray-900 dark:text-white">Invoice Date</span>
                <app-date class="due-by-date" [startKey]="'invoiceDate'" [parent]="currentShopOrder.accounting"
                [options]="{disabled:observables.locked, borderNone: true}" (onSave)="setAccounting('invoiceDate', $event.invoiceDate)"></app-date>
              </div>
              <div class="dates-item">
                <span class="block title font-medium text-gray-900 dark:text-white">Completed</span>
                <app-date [options]="{disabled:observables.locked, borderNone: true}" [title]="'Completed'" [startKey]="'completedAt'"
                [parent]="currentShopOrder" (onSave)="updateShopOrder($event)"></app-date>
              </div>
            </div>
          </div>
        }@else {
          <div class="w-100">
            <app-preloader [type]="'LARGE_LIST'" [count]="7" [customHeight]="'40px'"></app-preloader>
          </div>
        }
      </div>
    </div>
    <div>
      <table class="custom-table w-full" style="overflow: visible;" [ngStyle]="{'pointer-events': observables?.locked ? 'none' : null}">
        <thead>
          <tr>
            <th style="width: 10px;" *ngIf="!observables.locked"></th>
            <th style="width: 30px;">
              <input
                type="checkbox"
                [disabled]="observables.locked"
                placeholder="Enter Text"
                (change)="onSelectAll()"
                [checked]="_services().length && selectedServices.length == _services().length"
                class="custom-checkbox w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
              />
            </th>
            <th style="width: 30px;">#</th>
            <th>STATUS</th>
            <th>SERVICE</th>
            <th>TYPE</th>
            <th>DESCRIPTION</th>
            <th>HRS/QTY</th>
            <th>RATE</th>
            <th>AMOUNT</th>
            <th>MECHANIC</th>
            <th>UNIT</th>
            <th style="width: 60px;">DOCS</th>
          </tr>
        </thead>
        <tbody [nxtSortablejs]="_services()" [config]="sortableOptions">
          @if(currentShopOrder?._id){
            @for(service of _services() | sort: 'index'; track service._id){
              <tr
                [ngClass]="{'not-drag': observables.locked || filterValue(), 'service_item': selectedService?._id !== service?._id}"
                (mousedown)="onMouseDown($event)"
                (mousemove)="onMouseMove($event)"
                (click)="toggleEditMode(service, $event)"
              >
                <td class="text-center" *ngIf="!observables.locked" [ngClass]="{
                  'not-to-drag': filterValue()
                }">
                  <span class="drag-icon">
                    <i class="fas fa-ellipsis-v cursor-grabbing" style="color: #6B72804D;"></i>
                    <i class="fas fa-ellipsis-v cursor-grabbing" style="color: #6B72804D;"></i>
                  </span>
                </td>
                <td class="not-drag" (click)="$event.stopPropagation();">
                  <input
                    *ngIf="!observables.locked"
                    style="margin-left: 10px;"
                    class="custom-checkbox w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
                    type="checkbox"
                    placeholder="Enter Text"
                    [checked]="service?._selected"
                    (change)="selectService(service);"
                  />
                  <span *ngIf="observables.locked" class="lock-stop-icon">
                    <i class="fas fa-lock"></i>
                  </span>
                </td>
                <td class="text-center not-drag">
                  {{service?.index}}
                </td>
                <td class="table-status-wr not-drag">
                  <span *ngIf="selectedService?._id !== service?._id"
                    class="table-status"
                    [ngClass]="{
                      'text-red-900 bg-red-100': service?.status === 'NEW',
                      'text-yellow-900 bg-yellow-100': service?.status === 'ACTIVE',
                      'text-green-900 bg-green-100': service?.status === 'COMPLETED',
                      'text-gray-900 bg-gray-100': service?.status === 'CLOSED',
                      'text-orabge-900 bg-orabge-100': service?.status === 'ON_HOLD'
                    }">
                    {{service?.status}}
                  </span>
                  <div (click)="handleClick($event)">
                    <app-select
                      *ngIf="selectedService?._id == service?._id"
                      [keyName]="{key:'_id', name:'name'}"
                      (onSave)="updateService({_id:service._id, status: $event}); service.status = $event"
                      [value]="service?.status"
                      [idInfo]="'edit-status-service'"
                      [list]="statuses"
                      [placeholder]="'Select Status'"
                      [options]="{noFirstOption: true, borderNone: true}"
                    ></app-select>
                  </div>
                </td>
                <td class="table-serviceType-wr not-drag"
                >
                  <div class="table-serviceType" *ngIf="service?.serviceType && (selectedService?._id !== service?._id)">
                    <p style="white-space: nowrap; width: fit-content;">{{serviceTypes[service?.serviceType]}}</p>
                  </div>
                  <div (click)="handleClick($event)">
                    <app-select
                      *ngIf="selectedService?._id == service?._id"
                      [keyName]="{key:'_id', name:'name'}"
                      (onSave)="updateService({_id:service._id, serviceType: $event}); service.serviceType = $event"
                      [idInfo]="'edit-status-service'"
                      [value]="service?.serviceType"
                      [list]="currentServiceTypes"
                      [placeholder]="'Select Status'"
                      [options]="{noFirstOption: true, borderNone: true}"
                    ></app-select>
                  </div>
                </td>
                <td class="table-type not-drag">
                  <div class="table-item-view cursor-default" style="white-space: nowrap;" *ngIf="selectedService?._id !== service?._id">
                    <i class="fa-solid" [ngClass]="{'fa-hand': service?.type == 'LABOR', 'fa-gears': service?.type == 'PART'}"></i>
                    <span *ngIf="service?.type == 'LABOR'">Labor</span>
                    <span *ngIf="service?.type == 'PART'">Parts</span>
                  </div>

                  <div (click)="handleClick($event)">
                    <app-select
                      *ngIf="selectedService?._id == service?._id"
                      [list]="[{key:'LABOR', name: 'Labor'}, {key:'PART', name: 'Part'}]"
                      [value]="service?.type"
                      [options]="{noFirstOption: true, borderNone: true}"
                      [placeholder]="'Select'"
                      (onSave)="updateService({_id:service._id, type: $event}); service.type = $event; countAmounts(services())"
                    ></app-select>
                  </div>
                </td>
                <td class="table-description-rw not-drag">
                  <div *ngIf="selectedService?._id !== service?._id" [ngClass]="{'table-item-view': service?.desc && service?.desc !== ''}" style="max-width: 100%;" class="cursor-default mt-2 mb-2">
                    <p class="table-description" style="word-break: break-all;">{{service?.desc}}</p>
                  </div>
                  <div *ngIf="selectedService?._id == service?._id" >
                    <textarea
                      [value]="service?.desc || ''"
                      (input)="onDescChange($event, service); service.desc = $any($event.target).value"
                      (click)="handleClick($event)"
                      autogrow
                      rows="2"
                      id="description"
                      class="block w-full mt-2 mb-2 description-textarea text-gray-900 bg-gray-50 rounded-lg border-none focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Write your thoughts here..."
                      style="overflow-y: auto;"
                    ></textarea>
                  </div>
                </td>
                <td class="table-qty not-drag">
                  <div class="table-qty-wr table-item-view cursor-default" *ngIf="selectedService?._id !== service?._id">
                    <span>{{service?.qty}}</span>
                    <span>{{service?.type==='LABOR'?'Hours':'Quantity'}}</span>
                  </div>
                  <div (click)="handleClick($event)" *ngIf="selectedService?._id == service?._id">
                    <app-input
                      [type]="'number'"
                      [label]="service?.type==='LABOR'?'Hours':'Quantity'"
                      [value]="service?.qty"
                      (onChange)="updateService({_id:service._id, qty: $event, rate: service?.rate || 0, amount: $event * (service.rate || 0)});
                        service.qty = $event;
                        service.rate = service?.rate || 0;
                        service.amount = $event * (service.rate || 0); countAmounts(services())
                      "
                      [debounceEnable]="true"
                      [options]="{borderNone: true}"
                      [debounceTime]="600"
                      [disableValidation]="true"
                      [hightLightIfNotEmpty]="true"
                      [idInfo]="'service-qty'"
                    ></app-input>
                  </div>
                </td>
                <td class="service-price-table not-drag">
                  <div [ngClass]="{'table-item-view cursor-default': selectedService?._id !== service?._id}">
                    <app-price
                      [idInfo]="service?._id+'rate'"
                      [options]="{borderNone: true}"
                      (onSave)="updateService({_id:service._id,rate:$event, qty: service?.qty||1, amount: $event *  (service.qty || 1)});
                        service.rate = $event;
                        service.qty = service?.qty || 1;
                        service.amount = $event *  (service.qty || 1); countAmounts(services())
                      "
                      (click)="selectedService?._id == service?._id ? handleClick($event) : null"
                      [value]="service?.rate"
                      [label]="'Rate'"
                      [debounceEnable]="true"
                      [debounceTime]="600"
                      [isReadOnly]="selectedService?._id !== service?._id || observables.locked"
                      [readView]="selectedService?._id !== service?._id || observables.locked"
                    ></app-price>
                  </div>
                </td>
                <td class="service-price-table not-drag">
                  <div [ngClass]="{'table-item-view cursor-default': selectedService?._id !== service?._id}">
                    <app-price
                      [idInfo]="service?._id+'amount'"
                      [options]="{borderNone: true}"
                      (onSave)="updateService({_id:service._id,amount:$event, qty: service?.qty||1, rate: mathFloor($event / (service.qty || 1))});
                        service.amount = $event;
                        service.qty = service?.qty || 1;
                        service.rate = mathFloor($event / (service.qty || 1)); countAmounts(services())
                      "
                      (click)="selectedService?._id == service?._id ? handleClick($event) : null"
                      [value]="service?.amount"
                      [debounceEnable]="true"
                      [debounceTime]="600"
                      [isReadOnly]="selectedService?._id !== service?._id || observables.locked"
                      [readView]="selectedService?._id !== service?._id || observables.locked"
                      [label]="'Amount'"
                    ></app-price>
                  </div>
                </td>
                <td class="table-mechanic not-drag">
                  <div class="flex items-center table-mechanic-wr cursor-default" *ngIf="selectedService?._id !== service?._id">
                    <i class="fa-solid" [ngClass]="[service?.mechanic?._id ? 'fa-wrench' : 'fa-plus']"></i>
                    <p style="white-space: nowrap;">{{service?.mechanic?._id ? service?.mechanic?.name : 'Assign mechanic'}}</p>
                  </div>
                  <div (click)="handleClick($event)" *ngIf="selectedService?._id == service?._id">
                    <app-input
                      *ngIf="service?.mechanic?._id"
                      [value]="service?.mechanic?.name"
                      [options]="{canRemove: true, borderNone: true}"
                      (onSave)="updateService({_id:service._id, qty: $event})"
                      (onRemove)="setServiceMechanic({}, service); service.mechanic = {}"
                      [disabled]="true"
                    ></app-input>
                    <app-search
                      *ngIf="!service?.mechanic?._id"
                      [idInfo]="service?._id"
                      [label]="'Mechanic'"
                      [resource]="'CONTACT'"
                      [options]="{hideCreate: true, borderNone: true}"
                      [isReadOnly]="observables.locked"
                      [method]="'search'"
                      [filter]="{accountId: currentUser?.company?._id, type: 'MECHANIC', limit: 25, sort: 'quoteCount', dir: 'desc', active: true}"
                      (onChange)="setServiceMechanic($event, service); service.mechanic = $event"
                    ></app-search>
                  </div>
                </td>
                <td class="table-unit not-drag">
                  <div *ngIf="selectedService?._id !== service?._id" class="flex items-center table-unit-wr cursor-default">
                    <i *ngIf="service?.unit?._id" class="fa-solid {{service?.unit?.unitType === 'TRUCK'?'fa-truck':'fa-trailer'}}"></i>
                    <i *ngIf="!service?.unit?._id" class="fa-solid fa-plus"></i>
                    <p style="white-space: nowrap;">{{service?.unit?._id ? service?.unit?.name : 'Select Unit'}}</p>
                  </div>
                  <div *ngIf="selectedService?._id == service?._id" (click)="handleClick($event)">
                    <app-select
                      [keyName]="{key:'_id', name:'name'}"
                      [list]="currentShopOrder?.unit"
                      [value]="service?.unit?._id"
                      [options]="{noFirstOption: true, borderNone: true}"
                      (onSave)="updateUnit({_id:service._id, value: $event});"
                    ></app-select>
                  </div>
                </td>
                <td class="not-drag">
                  <div (click)="selectedService?._id == service?._id ? handleClick($event) : null" [ngClass]="{'edit_file_btn': selectedService?._id == service?._id, 'import_file cursor-default': selectedService?._id !== service?._id}">
                    <button (click)="selectedService?._id == service?._id ? openPreview(service) : null" [ngClass]="{'cursor-default': selectedService?._id !== service?._id}" class="relative">
                      <i class="fa-solid fa-file-import"></i>

                      <span *ngIf="service?._fileCount" class="count">{{service?._fileCount}}</span>
                    </button>
                  </div>
                </td>
              </tr>
            }@empty {
              <!-- !!! Change all table to div's !!! -->
              <tr>
                <td style="width: 1px"></td>
                <td style="width: 1px"></td>
                <td style="width: 1px"></td>
                <td style="width: 130px;">
                  <p style="width: 70px; font-size: 12px;" class="text-gray-500">No services</p>
                </td>
              </tr>
            }
          }
        </tbody>
        <tfoot>
          @if(currentShopOrder?._id){
            <tr class="not-drag">
              <td class="add-new-service-wr" colspan="11">
                <button *ngIf="!observables.locked" class='text-gray-500 add-new-service' type="submit" (click)="addService()" [ngClass]="{'not-allowed': !currentShopOrder?.unit?.length}"  [disabled]="!currentShopOrder?.unit?.length">
                  + New Service
                </button>
                <span *ngIf="!currentShopOrder?._units?.length" class="service-notify">Please select the unit!</span>
              </td>
            </tr>
          }
        </tfoot>
      </table>

      @if(!currentShopOrder?._id){
        <div class="w-100 py-2">
          <app-preloader [type]="'CONTENT_LIST'" [count]="3"></app-preloader>
        </div>
      }
      <div class="contact-containers">
        <div class="notes-cont items" [ngClass]="observables.locked ? 'isLocked' : ''">
          @if(currentShopOrder?._id){
            <app-notes
              [options]="{ list: true }"
              [creator]="currentUser"
              [usersEntities]="users"
              [title]="'Notes'"
              [display]="{
                tabs: { notes: true },
                select: {
                assignee: true,
                visibility: true,
                date: true,
                typeTask: true,
                complete: true,
                title: 'Service Notes'
                }
              }"
              [creator]="currentUser"
              [isReadOnly]="observables.locked"
              [resource]="FOLLOW_UP_RESOURCE.SHOP_ORDER"
              [resourceId]="currentShopOrder?._id"
            ></app-notes>
          }@else {
            <div class="notes-block content-block block-shadow">
                <app-preloader [type]="'SMALL_LIST'" [count]="1"></app-preloader>
                <div class="mt-2">
                    <app-preloader [type]="'LARGE_LIST'" [count]="5"></app-preloader>
                </div>
            </div>
          }
        </div>
        <div class="filew-cont items">
          @if(currentShopOrder?._id){
            <app-files
              [title]="'Work Order files'"
              [lock]="observables.locked"
              [resource]="FOLLOW_UP_RESOURCE.SHOP_ORDER"
              [resourceId]="currentShopOrder?._id"
            ></app-files>
          }@else {
            <div class="notes-block content-block block-shadow">
                <app-preloader [type]="'SMALL_LIST'" [count]="2"></app-preloader>
                <div class="mt-2">
                    <app-preloader [type]="'LARGE_LIST'" [count]="2"></app-preloader>
                </div>
            </div>
          }
        </div>
        <div class="accouting-cont items" style="background-color: rgb(255, 255, 255);">
          <p class="title">Accounting</p>
          @if(currentShopOrder?._id){
            <div class="accouting-cont-item">
              <p for="Invoice_number" class="block mb-2 subtitle font-medium text-gray-900 dark:text-white">Invoice number</p>
              <input [disabled]="observables.locked" (blur)="setAccounting('invoiceNumber', $any($event.target).value)" [value]="currentShopOrder?.accounting?.invoiceNumber && currentShopOrder?.accounting?.invoiceNumber !== 'undefined' ? currentShopOrder?.accounting?.invoiceNumber || '' : ''" type="text" id="Invoice_number" class="bg-gray-50 border-none text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Input number" required>
            </div>
            <div class="price-total-wr">
              <div class="item">
                <p class="subtitle">Total Parts</p>
                <div class="price-cont">
                  <app-price
                    [value]="totalParts"
                    [isReadOnly]="true"
                    [options]="{borderNone: true, medium: true, textBase: true }"
                    [icon]="'fa-solid fa-dollar-sign'"
                    (onSave)="setAccounting('amounts', {parts: $event, labor: currentShopOrder?.accounting?.amounts?.labor + 0})">
                  </app-price>
                </div>
              </div>
              <div class="item">
                <p class="subtitle">Total Labor</p>
                <div class="price-cont">
                  <app-price
                    [value]="totalLabors"
                    [isReadOnly]="true"
                    [options]="{borderNone: true, medium: true, textBase: true}"
                    [icon]="'fa-solid fa-dollar-sign'"
                    (onSave)="setAccounting('amounts', {labor: $event, parts: currentShopOrder?.accounting?.amounts?.parts + 0})">
                  </app-price>
                </div>
              </div>
            </div>
            <div class="price-total-wr">
              <div class="item">
                <p class="title">Grand Total</p>
                <div class="price-cont">
                  <app-price
                    [options]="{borderNone: true, medium: true, black: true, opatity: true, right: true, textBase: true }"
                    [readView]="true"
                    [value]="totalAmount">
                  </app-price>
                </div>
              </div>
            </div>
          }@else {
            <div class="w-100 px-1 py-2">
              <app-preloader [type]="'LARGE_LIST'" [count]="4" [customHeight]="'40px'"></app-preloader>
            </div>
          }
        </div>
      </div>
    </div>
  </div>
</div>


