import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import * as fromCoreStore from '@app/core/store'
import * as fromUserStore from '@app/users/store'
import * as fromShopStore from '@app/shop/store'
import { Store } from '@ngrx/store'
import { Observable, Subject } from 'rxjs'
import { map, takeUntil } from 'rxjs/operators'
import { CloseModal } from '@app/modals/store/actions/layout.actions'
import { Equipment } from '@app/shared'
import { ToastHelper } from '@app/core/services/toast.service'
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, } from '@angular/forms'

@Component({
  selector: 'app-shop-modal',
  templateUrl: './shop-modal.component.html',
  styleUrls: ['./shop-modal.component.scss']
})
export class ShopModalComponent implements OnInit, OnDestroy {
  @Input()
  set props(v) {
    this.carrierId = v.currentUser?.company._id
    this.carrierName = v.currentUser?.company.name
    this.form.get('carrierId').setValue(v.currentUser?.company._id)
    if (v.currentUser?.type == 'MECHANIC') {
      this.service.mechanic = {
        _id: v.currentUser?._id,
        name: v.currentUser?.name
      }
    }
  }

  destroyed$ = new Subject<boolean>()
  units$: Observable<any>
  units
  service: {
    unit?: {
      _id: String,
      name: String,
      unitType: String,
    },
    mechanic?: {
      _id: String,
      name: String
    },
    shop?: {
      _id: String,
      name: String
    },
    serviceType?: String,
    status: String,
    desc: String,
    dueBy?: String,
    odometer?: string
  } = {
      status: 'NEW',
      desc: '',
      unit: {
        _id: '',
        name: '',
        unitType: '',
      },
      serviceType: ''
    }
  saveButtonIsActive = false;
  form: UntypedFormGroup
  invalid: boolean = false;
  serviceType: string = '';
  unitId: string = '';

  constructor(
    private store: Store<fromCoreStore.State>,
    private shopStore: Store<fromShopStore.State>,
    private _Toast: ToastHelper,
    private fb: UntypedFormBuilder,
  ) {
    this.form = this.fb.group({
      carrierId: new UntypedFormControl(this.carrierId, {
        validators: [Validators.required],
        updateOn: 'change'
      }),
      unitId: new UntypedFormControl(this.unitId, {
        validators: [Validators.required],
        updateOn: 'change'
      }),
      serviceType: new UntypedFormControl(this.serviceType, {
        validators: [Validators.required],
        updateOn: 'change'
      }),
    })
  }

  carrierId: string
  carrierName: string
  onClick: boolean = false;
  ngOnInit(): void {
  }

  setShopService(field, event) {
    let value = event.target.value
    this.service[field] = value
    if (field != 'status') {
      this[field] = value
      this.form.get(field).setValue(value)
    }
    this.checkFormIsValid()
  }

  setEquipment(equipmentId, equipmentName, equipmentType) {
    this.service.unit = {
      _id: equipmentId,
      name: equipmentName,
      unitType: equipmentType,
    }
    this['unitId'] = equipmentId
    this.form.get('unitId').setValue(equipmentId)
    this.checkFormIsValid()
  }

  setMechanic(event) {
    this.service.mechanic = {
      _id: event._id,
      name: event.name
    }
    this.checkFormIsValid()
  }

  setDueDate(event) {
    this.service.dueBy = event.dueBy
  }

  setOdometer(event) {
    // console.log(event)
    this.service.odometer = event
  }

  setCarrier(carrierId, carrierName) {
    this.carrierId = carrierId
    this.carrierName = carrierName
    this.form.get('carrierId').setValue(carrierId)
  }

  setShop(event) {
    this.service.shop = {
      _id: event._id,
      name: event.name
    }
  }

  checkFormIsValid() {
    this.service.serviceType != '' && this.service?.unit?._id != ''
      ? (this.saveButtonIsActive = true)
      : (this.saveButtonIsActive = false)
  }

  save() {
    this.onClick = true
    if (this.saveButtonIsActive == true && this.form.valid) {
      this.shopStore.dispatch(new fromShopStore.CreateShopService(this.service))
      this.store.dispatch(new CloseModal())
    } else {
      this._Toast.error('Form invalid !', 10)
    }
  }

  close() {
    this.store.dispatch(new CloseModal())
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }
}
